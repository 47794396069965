import React from "react"
import { graphql } from "gatsby"
import Hub from "../components/Hub.js"
import favicon from "../assets/favicon.png"
import defaultImage from "../assets/default-image.jpg"

const CreationsHub = ({ location, data }) => {
  return (
    <Hub 
      page={data.prismicCreationshub} 
      slides={data.allPrismicCreation.nodes} 
      tags={data.allPrismicTag.nodes}
      location={location} 
      hub="creations"
    />
  )
}

export default CreationsHub


export const Head = ({ data }) => (
  <>
      <title>{`${data.prismicCreationshub.data.title.text.toLowerCase()} → the sun project`}</title>
      <meta name="description" content={data.prismicCreationshub.data.seo_description.text} />
      <meta name="keywords" content={data.prismicCreationshub.data.seo_keywords.text} />
      <meta name="author" content="the sun project" />
      
      <meta property="og:title" content={`${data.prismicCreationshub.data.title.text.toLowerCase()} → the sun project`} />
      <meta property="og:site_name" content="the sun project" />
      <meta property="og:description" content={data.prismicCreationshub.data.seo_description.text} />
      <meta property="og:image" content={`https://thesunproject.fr${defaultImage}`} />
      <meta property="og:locale" content={data.prismicCreationshub.lang} />
      <meta property="og:type" content="website" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="the sun project" />
      <meta name="twitter:title" content={`${data.prismicCreationshub.data.title.text.toLowerCase()} → the sun project`} />
      <meta name="twitter:description" content={data.prismicCreationshub.data.seo_description.text} />
      <meta name="twitter:image" content={`https://thesunproject.fr${defaultImage}`} />

      <link rel="icon" type="image/png" href={favicon} />
  </>

)

export const pageQuery = graphql`
  query SlideshowBySlug($uid: String, $lang: String) {
    prismicCreationshub(id: { eq: $uid }, lang: { eq: $lang }) {
      lang
      uid
      type
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        title {
          text
        }
        seo_keywords {
          text
        }
        seo_description {
          text
        }
      }
    },
    allPrismicCreation(
      filter: {lang: { eq: $lang }}
      sort: {fields: data___date, order: DESC}
    ) {
      nodes {
        lang
        uid
        alternate_languages {
          id
          type
          lang
          uid
        }
        data {
          title {
            text
          }
          main_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 3000
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          page_color
          client {
            text
          }
          date
          tags {
            tag {
              uid
            }
          }
        }
        type
      }
    }
    allPrismicTag(
      filter: {lang: { eq: $lang }}
      sort: {fields: data___position, order: ASC}
    ) {
      nodes {
        lang
        uid
        alternate_languages {
          id
          type
          lang
          uid
        }
        data {
          name {
            text
          }
          position
        }
      }
    }
  }
`